import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';

// New css Clients


export const TTContainer = styled.div`
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: #FFF;

    @media screen and (max-width: 1024px) {
        height: 300px;
    }

    @media screen and (max-width: 768px) {
        height: 200px;
    }

`;

export const TTWrapper = styled.div`
    
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;

`;

export const ServicesH1 = styled.h1`
    font-size: 4rem;
    color: #83C441;

    align-items: center;
    text-align: center; 
   

    @media screen and (max-width: 768px) {
        font-size: 3rem;
       
    }    

    @media screen and (max-width: 480px) {
        font-size: 2.5rem;
        
    }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  
`;

export const TTLink = styled(Link)`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageClients = styled.img`
    width: 200px;
    height: 150px;
    object-fit: fill;
    border-radius: 5px;
    margin: 20px;
    position: relative;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1024px) {
        width: 150px;
        height: 100px;
    }

    @media screen and (max-width: 768px) {
        width: 100px;
        height: 50px;
    }

    @media screen and (max-width: 480px) {
        width: 50px;
        height: 25px;
    }


`;

export const CollectionC = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: flex;
    padding: 2  0px;
    justify-content: center;
    align-items: center;
    background: #FFF;



`;

export const StyledCarousel = styled(Carousel)`
    width: 900px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
        width: 650px;
    }

    @media screen and (max-width: 768px) {
        width: 500px;
    }
    
    @media screen and (max-width: 480px){
        width: 350px;
    }
    .rec.rec-arrow {
        border-radius: 0;
        color: #83c441;
        font-size: 1rem;
        padding: 20px;
        background: none;
        box-shadow: none;        

    }

    .rec.rec-pagination button {
        display: absolute;
        background-color: transparent;
        border: none;
        width: 5px;
        height: 5px;
        cursor: pointer;
        outline-color: none;
        color: #000;
        transform: translateY(-80%);
        margin-bottom: -10px;
    
        &:focus {
            box-shadow: 0 0 0 2px #83c441;
        }

        &.rec-dot_active {
            box-shadow: 0 0 0 2px #83c441;
        }
    }
      


`;


