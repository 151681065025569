import styled from 'styled-components'

export const ModalWrapper = styled.div`
    width: 100%;
    height: 1000px;
    background: #050505;
    position: absolute;
    z-index: 1;

    @media screen and (max-width: 1280px) {
        
        height: 1300px;
    }

    @media screen and (max-width: 1024px) {
        
        height: 1000px;
    }
    
    @media screen and (max-width: 480px) {
       
        height: 1500px;
    }
    
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    p {
        margin-bottom: 1rem;
    }

    button {
        padding: 10px 24px;
        background: #141414;
        color: #fff;
        border: none;
    }
`;
export const CloseModalButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 40px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
    background: transparent;
    color: #FFF;
    border: none;
    outline: none;
    font-size: 2rem;
    
    @media screen and (max-width: 480px) {
        top: 0px;
        width: 1px;
        height: 1px;
        right: 50px;
    }

`;


