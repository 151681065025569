import React from 'react';
import { 
        SidebarContainer,
        Icon, 
        CloseIcon,
        SidebarWrapper,
        SidebarMenu,
        SidebarLink,
        SideBtnWrap,
        SidebarRoute
} from './SidebarElements';
import { Button } from '../ButtonElements';

const Sidebar = ({isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle} >About</SidebarLink>
                    <SidebarLink to="projects" onClick={toggle} >Projects</SidebarLink>
                    <SidebarLink to="services" onClick={toggle} >Services</SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarLink to="contacts" onClick={toggle} ><Button>Contact me</Button></SidebarLink>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>     
    );
};

export default Sidebar;