import React, { useState, useEffect } from 'react';
import { ProjectsContainer, ProjectsH1, ProjectsBAWrapper, ProjectsCard, ProjectsIcon, ProjectsH2, Title, ProjectsIcon2, StyledCarousel, ProjectsH4 } from '../ProjectsElements';
import { Modal } from '../Modal/index';
import { collectionGroup, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import VideoPopup from '../Modal/videop';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Projects = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [images, setImages] = useState({});

  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  useEffect(() => {
    loadAllImages();
  }, []);

  const loadAllImages = async () => {
    try {
      const collections = [
        'Brooklyn Lab',
      ];

      const fetchImageUrls = async (collection) => {
        const querySnapshot = await getDocs(query(collectionGroup(db, collection)));
        return querySnapshot.docs.map((doc) => doc.data().imageUrl);
      };

      const imagePromises = collections.map((collection) => fetchImageUrls(collection));
      const imageArrays = await Promise.all(imagePromises);

      const imagesObj = {};
      for (let i = 0; i < collections.length; i++) {
        imagesObj[collections[i]] = imageArrays[i];
      }

      setImages(imagesObj);
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };

  return (
    <ProjectsContainer id="projects">
      <ProjectsH1>BK LAB</ProjectsH1>
      <Modal showModal={showModal} setShowModal={setShowModal} modalContent={modalContent} />
      {images && (
          <ProjectsBAWrapper itemsToShow={1} breakPoints={breakPoints}>
            {/* BEFORE */}
            <ProjectsCard>
                <ProjectsIcon src={images['Brooklyn Lab'] && images['Brooklyn Lab'][0]} onClick={() => openModal('Beforebklab')} />
              <ProjectsH2>Before</ProjectsH2>
            </ProjectsCard>
            {/* AFTER */}
            <ProjectsCard>
                <ProjectsIcon src={images['Brooklyn Lab'] && images['Brooklyn Lab'][18]} onClick={() => openModal('Afterbklab')} />
              <ProjectsH2>After</ProjectsH2>
              
            </ProjectsCard> 
          </ProjectsBAWrapper>
      )}
      <br></br>
      <br></br>
      <br></br>
    </ProjectsContainer>
  );
};

export default Projects;
