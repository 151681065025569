import styled from "styled-components";
import Carousel from 'react-elastic-carousel';
import { Link as LinkR } from 'react-router-dom';
import logovv from '../logoc.png';

export const ProjectsContainer = styled.div`
    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #5E5E5E;
    background-image: url(${logovv});
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-size: 100%;
    background-position: center;
    background-attachment: scroll;


    @media screen and (max-width: 1024px) {
        height: 1000px;
    }


    @media screen and (max-width: 480px) {
        height: 1500px;
        
    }
`;

export const ProjectsH1 = styled.h1`
    width: 1200px;
    font-size: 5rem;
    color: #FFF;
    border-bottom: 5px solid #83c441;
    border-size: 5px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 0px;
    text-shadow: 1px 1px 2px black;

    @media screen and (max-width: 1200px) {
        
        width: 600px;
    }

    @media screen and (max-width: 768px) {
        
        width: 400px;
        
    }

    @media screen and (max-width: 480px) {
        font-size: 2.5rem;
        width: 350px;
    }
`;

export const Title = styled.h1`
    max-width: 1200px;
    font-size: 3rem;
    color: #83c441;
    text-shadow: 1px 1px 2px black;
    text-align: center;
    padding-top: 100px;

    @media screen and (max-width: 1200px) {
        font-size: 1.5rem;
        width: 600px;
    }

    @media screen and (max-width: 744px) {
        font-size: 1.5rem;
        width: 400px;
        
    }

    @media screen and (max-width: 414px) {
        font-size: 1.2rem;
        font-weight: 400;
        width: 350px;
    }

    @media screen and (max-width: 390px) {
        width: 300px;
    }
`;

export const StyledCarousel = styled(Carousel)`
    .rec.rec-arrow {
        border-radius: 0;
        color: #83c441;
        font-size: 5rem;
        width: 50px;
        height: 50px;

        background: none;
        box-shadow: none;
        
 
    }

    .rec.rec-pagination button {
        display: none;
    }

`;

export const ProjectsWrapper = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    align-items: center;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr ;
    }
    
    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
    }

`;

export const ProjectsBAWrapper = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;

    align-items: center;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr ;
    }
    
    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
    }

`;

export const ProjectsCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    
    transition: all 0.2s ease-in-out;



`;

export const ProjectsIcon = styled.img`
    height: 180px;
    width: 280px;
    
    
    outline: none;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.55);

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        box-shadow: 0 0 0.4em #83c441, 0 0 0.4em #83c441;
        text-shadow: 1px 1px 2px black, 0 0 1em #83c441, 0 0 0.2em #83c441;
    }


    @media screen and (max-width:768 px) {
        height: 110px;
        width: 200px;
        
    }

    @media screen and (max-width: 1024px) {
        height: 150px;
        width: 250px;
        
    }

`;

export const ProjectsIcon2 = styled(LinkR)`
    position: relative;
    height: 180px;
    width: 280px;
    cursor: pointer;
    background: #FFF;
    
    outline: none;
    border: none;
    cursor: pointer;
    
    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        box-shadow: 0 0 0.4em #83c441, 0 0 0.4em #83c441;
        text-shadow: 1px 1px 2px black, 0 0 1em #83c441, 0 0 0.2em #83c441;
    }


    @media screen and (max-width: 768px) {
        height: 110px;
        width: 200px;
    }

    @media screen and (max-width: 1024px) {
        height: 150px;
        width: 250px;
    }

`;

export const ProjectsH2 = styled.h2`
  font-size: 2rem;
  margin-top: 5px;
  color: #FFF;
  text-shadow: 1px 1px 2px black, 0 0 1em #050505;
  padding-left: 20px;
  padding-right: 20px;

    @media screen and (max-width: 1024px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    
    }

    @media screen and (max-width: 414px) {
    font-size: 0.8rem;
    
    }
`;

export const ProjectsH3 = styled.h2`
    font-size: 1.5rem;   
    color: #FFF;
    background:#83c441;;
    padding-left: 5px;
    padding-right: 15px;
    margin-top: 5px;
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: flex;
    border-radius: 20px 5px 5px 20px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);


    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        box-shadow: 0 0 0.4em #83c441, 0 0 0.4em #83c441;
    }

    .playIcon {
        margin-top: 3px;
        margin-right: 15px;
        background-size: 100px;
        border-radius: 25px 25px 25px 25px;
        
        border: 1px solid #FFF;
        animation: mymove 2s infinite;

        @keyframes mymove {
            50% {border: 0.5px solid #83c441;}
     }
    
    &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    }

    
      
    @media screen and (max-width: 480px) {
        font-size: 0.8rem;
        
    }

`;

export const ProjectsH4 = styled.h2`
    font-size: 1.5rem;
    color: #FFF;
    background:#83c441;;
    padding-left: 5px;
    padding-right: 15px;
    margin-top: 5px;
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: flex;
    border-radius: 20px 5px 5px 20px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    .playIcon {
        margin-top: 3px;
        margin-right: 15px;
        background-size: 100px;
        border-radius: 0px 25px 25px 0px;
        

     }
    
    &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    }

    @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    
    }

`;
