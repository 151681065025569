import React from 'react';
import {
    ModalWrapper,
    CloseModalButton
} from './ModalElements';
import MoxxyHotel from '../Gallery/MoxxyHotel';
import Walmart from '../Gallery/Walmart';
import SummitHouse from '../Gallery/SummitHouse';
import TommyOffice from '../Gallery/TommyOffice';
import RalphLauren from '../Gallery/RalphLauren';
import PublicStorage from '../Gallery/PublicStorage';
import BrooklynLab from '../Gallery/BrooklynLab';
import Chandelier from '../Gallery/Chandelier';
import Citibank from '../Gallery/Citibank';
import SevenElevenDollarTree from '../Gallery/7ElevenDollarTree.js';
import George from '../Gallery/George';
import NycThcM from '../Gallery/NycThcM';
import Grounding from '../Gallery/Grounding';
import NickHouse from '../Gallery/NickHouse';
import Burlington from '../Gallery/Burlington';
import KFCRiverhead from '../Gallery/KFCRiverhead';
import PopeyesWestfallT from '../Gallery/PopeyesWestfallT';
import KFCTBNorwalkCT from '../Gallery/KFCTBNorwalkCT';
import KFCDerbyCT from '../Gallery/KFCDerbyCT';
import Paneling from '../Gallery/Paneling';
import EvChargerStation from '../Gallery/EvChargerStation';
import ItRack from '../Gallery/ItRack';
import Admin from '../Gallery/Admin';
import Electrical from '../Projects/Electrical';
import Painting from '../Projects/Painting';
import ITProgramming from '../Projects/ITProgramming';
import Tiling from '../Gallery/Tiling';
import Thcnycba from '../Projects/Thcnycba';
import Afterthcnyc from '../Gallery/Afterthcnyc';
import Beforethcnyc from '../Gallery/Beforethcnyc';
import Bklabba from '../Projects/Bklabba';
import Afterbklab from '../Gallery/Afterbklab';
import Beforebklab from '../Gallery/Beforebklab';

import {FaWindowClose} from 'react-icons/fa';

export const Modal = ({ showModal, setShowModal, modalContent }) => {
    return (
        <>
        {showModal ? (
            <ModalWrapper showModal={showModal}>
                {modalContent === 'MoxxyHotel' && <MoxxyHotel />}
                {modalContent === 'Walmart' && <Walmart />}
                {modalContent === 'SummitHouse' && <SummitHouse />}
                {modalContent === 'TommyOffce' && <TommyOffice />}
                {modalContent === 'RalphLauren' && <RalphLauren />}
                {modalContent === 'PublicStorage' && <PublicStorage />}
                {modalContent === 'BrooklynLab' && <BrooklynLab />}
                {modalContent === 'Chandelier' && <Chandelier />}
                {modalContent === 'Citibank' && <Citibank />}
                {modalContent === 'SevenElevenDollarTree' && <SevenElevenDollarTree />}
                {modalContent === 'George' && <George />}
                {modalContent === 'NycThcM' && <NycThcM />}
                {modalContent === 'Grounding' && <Grounding />}
                {modalContent === 'NickHouse' && <NickHouse />}
                {modalContent === 'Burlington' && <Burlington />}
                {modalContent === 'EvChargerStation' && <EvChargerStation />}
                {modalContent === 'Paneling' && <Paneling />}
                {modalContent === 'KFCRiverhead' && <KFCRiverhead />}
                {modalContent === 'PopeyesWestfallT' && <PopeyesWestfallT />}
                {modalContent === 'KFCTBNorwalkCT' && <KFCTBNorwalkCT />}
                {modalContent === 'KFCDerbyCT' && <KFCDerbyCT />}
                {modalContent === 'ItRack' && <ItRack />}
                {modalContent === 'Admin' && <Admin />}
                {modalContent === 'Electrical' && <Electrical />}
                {modalContent === 'Painting' && <Painting />}
                {modalContent === 'ITProgramming' && <ITProgramming />}
                {modalContent === 'Tiling' && <Tiling />}
                {modalContent === 'Thcnycba' && <Thcnycba />}
                {modalContent === 'Afterthcnyc' && <Afterthcnyc />}
                {modalContent === 'Beforethcnyc' && <Beforethcnyc />}
                {modalContent === 'Bklabba' && <Bklabba />}
                {modalContent === 'Afterbklab' && <Afterbklab />}
                {modalContent === 'Beforebklab' && <Beforebklab />}
                
                <CloseModalButton onClick={() => setShowModal (prev => !prev)}>
                    <FaWindowClose/>
                </CloseModalButton>
            </ModalWrapper>
        ) : null}
        </>
    );
};