import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import AdminGallery from '../components/Gallery/Admin';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';

const Admin = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };


    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <HeroSection/>
        <AdminGallery />
        <Footer />
        </>
    );
};


export default Admin;