import React, { useState, useEffect } from 'react';
import {
  storage,
  db
} from "../../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from "firebase/storage";
import {
  doc,
  collection,
  setDoc,
  getDocs,
  deleteDoc
} from "firebase/firestore";
import {
  ImageNewGallery,
  Wrapper,
  CollectionNew,
  ModalImage,
  ModalWrapper,
  CloseButton,
  PrevButton,
  NextButton,
  XButton,
  PhotoCount
} from '../GalleryElements';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FaRegWindowClose } from 'react-icons/fa';

const Fileupload = () => {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [photoCount, setPhotoCount] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState("7Eleven Dollar Tree"); // Default selected collection

  const [open, setOpen] = useState(false);
  const CloseModal1 = () => setOpen(false);
  const OpenModal1 = () => setOpen(true);

  useEffect(() => {
    loadAllImages();
    countPhotos();
  }, [selectedCollection]); // Load images and count photos when selected collection changes

  const loadAllImages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, selectedCollection));
      const currImages = querySnapshot.docs.map((doc) => doc.data().imageUrl);
      setImages(currImages);
    } catch (error) {
      console.error("Error loading images:", error);
    }
  };

  const countPhotos = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, selectedCollection));
      setPhotoCount(querySnapshot.size);
    } catch (error) {
      console.error("Error counting photos:", error);
    }
  };

  const handleCollectionChange = (e) => {
    setSelectedCollection(e.target.value);
  };

  const handleChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleUpload = () => {
    if (files.length === 0) {
      alert("Please add files");
      return;
    }

    const uploadTasks = files.map((file) => {
      const storageRef = ref(storage, `${selectedCollection}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          setUploadProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("download url:", downloadURL);
            const imageStoreRef = doc(db, selectedCollection, file.name);
            setDoc(imageStoreRef, {
              imageUrl: downloadURL
            });
          });
        }
      );

      return uploadTask;
    });

    Promise.all(uploadTasks)
      .then(() => {
        console.log("All files uploaded successfully");
        alert("All files uploaded successfully");
        setFiles([]);
        loadAllImages(); // Reload images after upload
        countPhotos(); // Recount photos after upload
      })
      .catch((error) => {
        alert("Error uploading files");
        console.log("Error uploading files:", error);
      });
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const handlePrevClick = () => {
    if (!selectedImage) return;

    const currentIndex = images.indexOf(selectedImage);
    const prevIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setSelectedImage(images[prevIndex]);
  };

  const handleNextClick = () => {
    if (!selectedImage) return;

    const currentIndex = images.indexOf(selectedImage);
    const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setSelectedImage(images[nextIndex]);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteDocument = async (index) => {
    try {
      const imageUrl = images[index];
      const querySnapshot = await getDocs(collection(db, selectedCollection));

      querySnapshot.forEach(async (doc) => {
        if (doc.data().imageUrl === imageUrl) {
          await deleteDoc(doc.ref);
          await deleteObject(ref(storage, `${selectedCollection}/${doc.id}`)); // Delete file from Firebase Storage
          console.log("Document and file successfully deleted!");
          alert("Document and file successfully deleted!");
          loadAllImages(); // Reload images after deletion
          countPhotos(); // Recount photos after deletion
        }
      });
    } catch (error) {
      console.error("Error deleting document and file:");
      alert("Error deleting document and file:");
      loadAllImages(); // Reload images after deletion
      countPhotos(); // Recount photos after deletion
    }
  };

  return (
    <CollectionNew>
      {images &&
        images.map((imageUrl, index) => {
          return (
            <Wrapper key={index}>
              <ImageNewGallery src={imageUrl} onClick={() => openModal(imageUrl)} />
              <XButton onClick={() => deleteDocument(index)}>X</XButton>
            </Wrapper>
          );
        })}
      <div>
        <label>Select Collection:</label>
        <select value={selectedCollection} onChange={handleCollectionChange}>
          <option value="7Eleven Dollar Tree">7Eleven Dollar Tree</option>
          <option value="Brooklyn Lab">BrooklynLab</option>
          <option value="Burlington">Burlington</option>
          <option value="Chandelier">Chandelier</option>
          <option value="Citibank">Citibank</option>
          <option value="Moxxy Hotel">Moxxy Hotel</option>
          <option value="NYC THC M">NYC THC M</option>
          <option value="Nick House">Nick House</option>
          <option value="Tommy Office">Tommy Office</option>
          <option value="Ralph Lauren">Ralph Lauren</option>
          <option value="Summit House">Summit House</option>
          <option value="George">George</option>
          <option value="Grounding">Grounding</option>
          <option value="Public Storage">Public Storage</option>
          <option value="Walmart">Walmart</option>
          <option value="Paneling">Paneling</option>
          <option value="Ev Charger Station">Ev Charger Station</option>
          <option value="KFC River Head">KFC River Head</option>
          <option value="KFC Taco Bell Norwalk CT">KFC Taco Bell Norwalk CT</option>
          <option value="KFC Derby CT">KFC Derby CT</option>
          <option value="Popeyes Westfall Township">Popeyes Westfall Township</option>
          <option value="It Rack">It Rack</option>
          <option value="Tiling">Tiling</option>
          <option value="Clients">Clients Logo</option>
          <option value="After THC NYC">Afterthcnyc</option>
          <option value="Before THC NYC">Before thcnyc</option>
          <option value="UnderC">UnderC</option>
          <option value="After BK LAB">After BK LAB</option>
          <option value="Before BK LAB">Before BK LAB</option>
          
        </select>
      </div>
      <input type="file" multiple onChange={handleChange} />
      <button onClick={handleUpload}>Upload</button>
      {/* ... rest of the code */}
    </CollectionNew>
  );
};

export default Fileupload;
