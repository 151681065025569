import React, { useState, useEffect, useRef } from 'react';
import {
  db
} from "../../firebase";

import {
  collection,
  getDocs,
} from "firebase/firestore";
import {
  ImageThumbtack,
  Wrapper,
  CollectionTT,
  StyledCarousel,
  TTWrapper,
  CollectionTTS,
  ServicesH1,
  TTContainer,
  TTLink

} from './ThumbtElements';
import { ButtonT } from '../ButtonElements';

import 'reactjs-popup/dist/index.css';

const Thumbtack = () => {

    const [images, setImages] = useState([]);

  useEffect(() => {
    loadAllImages();
    
  }, []);

  const loadAllImages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Thumbtack"));
      const currImages = querySnapshot.docs.map((doc) => doc.data().imageUrl);
      setImages(currImages);
    } catch (error) {
      console.error("Error loading images:", error);
      
    }
  };

  const carouselRef = useRef(null);
  let resetTimeout; 

  return (
    <TTContainer id="thumbtack">
    <ServicesH1>Reviews Thumbtack</ServicesH1>
    <TTWrapper>
                
            <CollectionTTS itemsToShow={0}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[0]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTTS>
        <StyledCarousel 
            ref={carouselRef}
            enableAutoPlay={true}
            autoPlaySpeed={4000}
            isRTL={false}
            onNextEnd={({ index }) => {
                clearTimeout(resetTimeout);
                resetTimeout = setTimeout(() => {
                    carouselRef?.current?.goTo(0);
                }, 40000); // same time
            }}
        >
            <CollectionTT itemsToShow={1}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[1]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={2}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[2]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={3}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[3]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={4}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[4]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={5}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[5]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={6}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[6]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={7}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[7]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={8}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[8]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={9}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[9]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
            <CollectionTT itemsToShow={10}>
                {images && (
                    <Wrapper>
                        <ImageThumbtack
                        src={images[10]} // Display the first image
                    />
                    </Wrapper>
                )}  
            </CollectionTT>
        </StyledCarousel>
        
    </TTWrapper>
    <ButtonT ><TTLink to="https://www.thumbtack.com/nj/kearny/electrical-repairs/power-up-general-solution/service/419722667307835398?surface=sp">Go to Thumbtack</TTLink></ButtonT>
    </TTContainer>
  );
};

export default Thumbtack;
