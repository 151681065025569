import React, { useState, useEffect } from 'react';
import {
  db
} from "../../firebase";
import {
  collection,
  getDocs,
  
} from "firebase/firestore";
import {
  ImageNewGallery,
  Wrapper,
  CollectionNew,
  ModalImage,
  ModalWrapper,
  CloseButton,
  PrevButton,
  NextButton,

} from '../GalleryElements';

import 'reactjs-popup/dist/index.css';
import { FaRegWindowClose } from 'react-icons/fa';


const Fileupload = () => {
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  
  const [open, setOpen] = useState(false);
  const CloseModal1 = () => setOpen(false);
  const OpenModal1 = () => setOpen(true);

  useEffect(() => {
    loadAllImages();

  }, []);

  const loadAllImages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "KFC Derby CT"));
      const currImages = querySnapshot.docs.map((doc) => doc.data().imageUrl);
      setImages(currImages);
    } catch (error) {
      console.error("Error loading images:", error);
    }
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const handlePrevClick = () => {
    if (!selectedImage) return;
  
    const currentIndex = images.indexOf(selectedImage);
    const prevIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setSelectedImage(images[prevIndex]);
  };

  const handleNextClick = () => {
    if (!selectedImage) return;
  
    const currentIndex = images.indexOf(selectedImage);
    const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setSelectedImage(images[nextIndex]);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <CollectionNew>
      {images &&
        images.map((imageUrl, index) => {
          return (
            <Wrapper key={index}>
              <ImageNewGallery
                src={imageUrl}
                onClick={() => openModal(imageUrl)}
              />

            </Wrapper>
          );
        })}


      {isModalOpen && (
        <ModalWrapper>
          <ModalImage src={selectedImage} alt="Modal Image" />
          <CloseButton onClick={closeModal}>
            <FaRegWindowClose/>
          </CloseButton>
          <PrevButton onClick={handlePrevClick}>
            Prev
          </PrevButton>
          <NextButton onClick={handleNextClick}>
            Next
          </NextButton>
        </ModalWrapper>
      )}
    </CollectionNew>
  );
};

export default Fileupload;
